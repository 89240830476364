import {
  ButtonInlineLink,
  CenteredContentWrapper,
  device,
  EmphasizedText,
  Quote,
  Subheading,
  TextToutFullwidth,
  ThumbnailHeader,
} from '@xolvio/xolvio-ui'
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import {
  MoveHack,
  ToutContent,
  ToutHeader,
  ToutTextBody,
  ToutWrapper,
} from "../../components/Services";
import { LayoutDefault } from "../../components/LayoutDefault";
import { HeroBackgroundImage } from "../../components/HowWeWork";
import { ContentWrapper } from "quality-faster/src/components/LandingPage/ContentWrapper";
import { SectionHeader, TextBody } from "@xolvio/xolvio-ui/src";
import styled from "styled-components";
import { ToutRow } from "../../components/Homepage";
import {contactUsFooterProps} from '../../pages-content/homepage'

const Page = ({ data }) => {
  return (
    <>
      <ToutWrapper>
        <ToutContent>
          <ToutHeader>
            <EmphasizedText text={"StudyKIK"} highlight={"philosophy"} />
          </ToutHeader>
          <ToutTextBody style={{ textAlign: "center" }}>
            {"Complex monolithic system refactor & GraphQL API development"}
          </ToutTextBody>
        </ToutContent>
      </ToutWrapper>

      <MoveHack top={"150px"} />

      <CenteredContentWrapper>
        <Quote
          body={
            "In a market short in GraphQL expertise, Xolvio not only showed us exactly how to build our supergraph but also refactored our monolithic system in just three months."
          }
          signature={"Will Rigsbee, Senior VP of Engineering, StudyKIK"}
        />
      </CenteredContentWrapper>

      <MoveHack top={"150px"} />

      <HeroBackgroundImage
        fluid={data.howItWorksBg.childImageSharp.fluid}
        durationFadeIn={200}
        style={{
          position: "absolute", // this neeeds to be set here
          width: "200%",
          height: "350px",
        }}
      />

      <CenteredContentWrapper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "-50px",
        }}
      >
        <SectionHeaderStyled>
          Supergraph-powered Customer Experience
        </SectionHeaderStyled>
        <StyledTextBody>
          StudyKIK (a Syneos Health company) is a leading provider of clinical
          trial recruitment and retention technologies connecting patients,
          sites, and sponsors. However, their monolithic system architecture
          was increasingly hampering engineering productivity. StudyKIK turned
          to GraphQL with the aim of decoupling backend from frontend for
          greater user experience agility. Looking to support their GraphQL
          efforts, the company enlisted Xolvio, the official Apollo GraphQL
          professional services partner, to evaluate its GraphQL practice and
          to make recommendations for proper design and implementation of a
          federated Supergraph architecture. Following this assessment, Xolvio
          refactored StudyKIK’s monolith in three months and built a federated
          GraphQL API on top of it to supercharge innovation. As a result of
          the engagement, StudyKIK skyrocketed their ability to iterate on new
          features, consequently accelerating a new product launch by a full quarter.
        </StyledTextBody>
      </CenteredContentWrapper>

      <MoveHack top={"100px"} />

      <CenteredContentWrapper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Subheading style={{ textAlign: "center" }}>
          {"THE CHALLENGE"}
        </Subheading>
        <SectionHeader style={{ maxWidth: "100%", textAlign: "center" }}>
          {"Highly coupled, complex monolith"}
        </SectionHeader>
        <Solution>
          <SolutionParagraph>
            As StudyKIK’s monolithic system grew in complexity over the years,
            it became increasingly difficult to maintain. A high degree of
            coupling, especially around some entities, meant that new feature
            development required careful attention to many different parts of
            the system. This became particularly problematic for evolving
            customer experience, which led to StudyKIK introducing GraphQL to
            their tech stack, with the aim of decoupling backend from frontend.
          </SolutionParagraph>
          <SolutionParagraph>
            However, the monolithic system’s complexity made GraphQL schema
            design difficult and posed the risk of maintaining one giant GraphQL
            server. Consequently, a refactor of the monolith seemed inevitable.
            StudyKIK’s challenge was then threefold: to plan out a new,
            distributed, microservices architecture; to execute the refactor in
            a safe and controlled fashion; and to create a federated GraphQL
            schema to match the new architecture.
          </SolutionParagraph>
        </Solution>
      </CenteredContentWrapper>

      <MoveHack top={"100px"} />

      <CenteredContentWrapper>
        <ContentWrapper>
          <Subheading style={{ textAlign: "center" }}>
            {"THE SOLUTION"}
          </Subheading>
          <SectionHeader style={{ maxWidth: "1200px", textAlign: "center" }}>
            {"Domain-Driven Design for both system refactor & API development"}
          </SectionHeader>
          <TextBody style={{ maxWidth: '100%', textAlign: 'left' }}>
            Our engagement with StudyKIK began with <ButtonInlineLink
            href={'/services/apollo-graphql-professional-services'}
            style={{ padding: '0' }}
            as={"a"}
          >Apollo GraphQL consulting</ButtonInlineLink>. Over a period of two weeks, our team interviewed key product and engineering stakeholders, conducted <ButtonInlineLink
            href={'/blog/articles/event-storm-to-production-supergraph-a-domain-driven-design-approach-to-graphql/'}
            style={{ padding: '0' }}
            as={"a"}
          >event storming workshops</ButtonInlineLink>, and analyzed the gathered information to produce a set of targeted recommendations and a roadmap to follow. Trusting our expertise, StudyKIK then tasked us with refactoring their system and revamping their GraphQL journey by designing and implementing an Apollo supergraph.
          </TextBody>

          <MoveHack top={"50px"} />

          <StyledToutRow>
            <div>
              <ThumbnailHeader>{"Event Storming"}</ThumbnailHeader>
              <TextBody style={{ textAlign: "left" }}>
                {
                  "This highly effective workshop technique allowed us to quickly model StudyKIK’s business domains from a high level by extracting tacit knowledge from the relevant stakeholders and making it visible on a virtual whiteboard. Then, we dug deeper to document the relevant business processes in more detail, before using the modeled processes for software design and development."
                }
              </TextBody>
            </div>
            <RowItem>
              <ThumbnailHeader>{"Bounded Contexts"}</ThumbnailHeader>
              <TextBody style={{ textAlign: "left" }}>
                {
                  "In Domain-Driven Design, a bounded context is defined as a boundary of language consistency. These domain boundaries emerged from the Event Storming workshops and allowed us to define the new, distributed architecture in terms of modules. Later, we applied this domain-driven approach to GraphQL schema design, as bounded contexts naturally map to subgraphs in a supergraph."
                }
              </TextBody>
            </RowItem>
          </StyledToutRow>
          <MoveHack top={"-50px"} topMobile={"10px"} />

          <StyledToutRow>
            <div>
              <ThumbnailHeader>{"Refactor"}</ThumbnailHeader>
              <TextBody style={{ textAlign: "left" }}>
                {
                  "Armed with initial bounded contexts and a detailed understanding of StudyKIK’s business domains, we began the refactor in a stepwise fashion, creating a mixture of code repositories and microservices. In order to ensure great scalability, reliability and performance benefits, we applied an event-driven architecture with a message broker enabling decoupled communication between the different modules."
                }
              </TextBody>
            </div>
            <RowItem>
              <ThumbnailHeader>
                {"Digital Experience Integration"}
              </ThumbnailHeader>
              <TextBody style={{ textAlign: "left" }}>
                {
                  "In parallel to the refactor, we laid the foundations for StudyKIK’s GraphQL API by designing and implementing a federated GraphQL schema. We focused on current data demands on the client side, so that the GraphQL API could provide the correspondent supply through the appropriate subgraphs of the supergraph. This demand-driven approach allowed us to quickly build a fully operational API, ready to be expanded as per StudyKIK’s roadmap."
                }
              </TextBody>
            </RowItem>
          </StyledToutRow>
        </ContentWrapper>
      </CenteredContentWrapper>

      <MoveHack top={"50px"} topMobile={"0"} />

      <CenteredContentWrapper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "-50px",
        }}
      >
        <SectionHeaderStyled>
          <Subheading style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}>{"THE OUTCOME"}</Subheading>
          CX delivery transformation and roadmap acceleration
        </SectionHeaderStyled>
        <StyledTextBody>
          When we started our engagement with StudyKIK, their engineering organization was wrestling with a highly complex monolithic system that hampered their ability to improve on customer experience. Three and a half months later, they could boast a brand new, refactored, event-driven, distributed system with a federated GraphQL API on top. Apart from these hard deliverables, we armed StudyKIK teams with knowledge, skills, and best practices to continue growing both their system and the API. As a result, StudyKIK was able to accelerate their roadmap and moved an upcoming product launch by a full quarter.
        </StyledTextBody>
      </CenteredContentWrapper>

      <MoveHack top={"80px"} />

      <TextToutFullwidth {...contactUsFooterProps} />
    </>
  );
};

const TwoKCaseStudy = () => (
  <StaticQuery
    query={graphql`
      query {
        howItWorksBg: file(
          relativePath: { eq: "sectionhow-it-works-bg@2x.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1700) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <LayoutDefault>
          <Page data={data} />
        </LayoutDefault>
      );
    }}
  />
);

export default TwoKCaseStudy;

const AlignToLeftOnMobile = styled(TextBody)`
  max-width: 100%;
  text-align: center;

  @media ${device.mobile} {
    text-align: left;
  }
`;

const SectionHeaderStyled = styled(SectionHeader)`
  max-width: unset;
  text-align: center !important;
  margin-top: 16px;
  margin-bottom: 24px;

  @media ${device.mobile} {
    margin-top: 50px;
    font-size: 28px;
  }
`;

const StyledTextBody = styled(TextBody)`
  text-align: left;
  @media ${device.mobile} {
    max-width: 90%;
  }
  max-width: 70%;
`;

const Solution = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const SolutionParagraph = styled.p`
  flex: 1;
  color: #6b726f;
  font-size: 18px;
  line-height: 1.5;
`;

const AlignToLeftOnMobileSolutionParagraph = styled(SolutionParagraph)`
  max-width: 100%;
  font-size: 20px;
  text-align: center;

  @media ${device.mobile} {
    text-align: left;
  }
`;

const StyledToutRow = styled(ToutRow)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  & > div {
    flex: 1;
    * {
      max-width: unset;
    }
  }
`;

const RowItem = styled.div`
  @media ${device.mobile} {
    margin-top: -50px;
  }
`;
